import React, { useEffect } from "react";
import { Fragment, useState } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import "react-data-table-component-extensions/dist/index.css";
import {
  Badge,
  Breadcrumb,
  Button,
  Card,
  Col,
  Row,
  Modal,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import OfferService from "../../../Redux/services/OfferService";
import "../../Category/Category.scss";
import { useSelector } from "react-redux";
import moment from "moment/moment";
import user from "../../../assets/img/doctor_noimage.png";
import Swal from "sweetalert2";
import BaseURL from "../../../data/Api/baseUrl";
import Lottie from "lottie-react";
import animation from "../../../assets/food/95494-double-loader.json";
import { useNavigate } from "react-router-dom";
import ShopSettingsService from "../../../Redux/services/ShopSettingsService";
import AddPoints from "./addPointsModal";
import AppSettingService from "../../../Redux/services/AppSettingService";

function WomPoints() {
  const [totalRows, setTotalRows] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPerPage] = useState(100);
  const [data, setData] = useState([]);
  const currentUser = useSelector((state) => state.auth);
  const [editCategory, setEditCategory] = useState("");
  const [viewEditCategory, setViewEditCategory] = useState(false);

  const navigate = useNavigate();

  const showEditCategory = (item) => {
    setopenCategory(true);
    setEditCategory(item);
    setIsUpdate(true);
  };

  const [loading, setLoading] = useState(true);
  const showTierItems = async () => {
    try {
      await AppSettingService.getLoyalityItems(currentPage, pageLimit).then(
        (res) => {
          setData(res.data?.tierItems?.data);
          setTotalRows(res.data.tierItems?.total);
          setLoading(false);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const [deleteCategory, setDeleteCategory] = useState(undefined);
  const handleDeleteCategory = async () => {
    await OfferService.remove(deleteCategory._id);
    setDeleteCategory(undefined);
  };

  const [opencategory, setopenCategory] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const handleClickCategory = () => {
    setopenCategory(true);
  };
  const handleClose = () => {
    setopenCategory(false);
    setIsUpdate(false);
  };
  useEffect(() => {
    showTierItems();
  }, [currentPage, pageLimit]);

  const handleDelete = (row) => {
    console.log("delete_id", row);
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-primary me-2",
        cancelButton: "btn btn-primary me-2",
        allowOutsideClick: false,
      },
      buttonsStyling: true,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Delete Offer?",
        text: "Are you sure you want to delete this Offer?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        allowOutsideClick: false,
        confirmButtonColor: "rgb(255, 0, 0)",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            let response = BaseURL.delete(
              `admin/offers/delete_offer/${row.id}`
            );
            response.then((res) => {
              showTierItems();
              console.log("res", res.data);
            });
          } catch (error) {
            console.log(error);
          }
          swalWithBootstrapButtons.fire(
            "Deleted!",
            "Offer has been deleted successfully.",
            "success"
          );
        }
      });
  };

  const columns = [
    {
      name: "Title",
      selector: (row) => console.log("row", row),
      cell: (row) => <div>{row?.title}</div>,
    },
    {
      name: "Image",
      selector: (row) => [row?.image_url],
      cell: (row) =>
        row?.image_url ? (
          <img src={row?.image_url} width={"50px"} />
        ) : (
          <img src={user} width={"50px"} />
        ),
    },
    {
      name: "Description",
      selector: (row) => [row?.description],
      cell: (row) => <div>{row?.description}</div>,
    },
    {
      name: "Point Amount",
      selector: (row) => [row?.point_amount],
      cell: (row) => <div>{row?.point_amount}</div>,
    },
    {
      name: "Usage Count",
      selector: (row) => [row?.usage_count],
      cell: (row) => <div>{row?.usage_count}</div>,
    },
    {
      name: "Maximum Usage",
      selector: (row) => [row?.maximum_usage],
      cell: (row) => <div>{row?.maximum_usage}</div>,
    },
    {
      name: "Loyalty",
      selector: (row) => [row?.loyalty?.name_en],
      cell: (row) => <div>{row?.loyalty?.name_en}</div>,
    },
    {
      name: "ACTIONS",
      selector: (row) => [row.ACTIONS],

      cell: (row) => (
        <div className="button-list">
          <OverlayTrigger overlay={<Tooltip> Edit</Tooltip>}>
            <i
              onClick={() => showEditCategory(row)}
              className="ti ti-pencil btn"
            ></i>
          </OverlayTrigger>
        </div>
      ),
    },
    {
      name: "Updated At",
      selector: (row) => [row.icon],
      cell: (row) => <div>{moment(row.updated_at).format("LL")}</div>,
    },
  ];

  // var click = (id) => {
  //   let i = data.filter((e, index) => {
  //     return e.ID !== id;
  //   });
  //   setData(i);
  // };
  const tableData = {
    columns,
    data,
  };

  if (loading) {
    return (
      <div className="animie_shops">
        <Lottie
          animationData={animation}
          loop={true}
          style={{ width: "500px" }}
        />
      </div>
    );
  } else {
    return (
      <div>
        <Fragment>
          {/* <!-- Page Header --> */}
          <div className="page-header">
            <div>
              <h2 className="main-content-title tx-24 mg-b-5">Wom Points</h2>
              <Breadcrumb>
                <Breadcrumb.Item href="#">All</Breadcrumb.Item>
                <Breadcrumb.Item active>Wom Points</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          {/* <!-- End Page Header --> */}
          {/* <!-- Row --> */}
          <Row className="row-sm">
            <Col md={12} lg={12}>
              <Card className="custom-card">
                <Card.Header className=" border-bottom-0">
                  <div className="addCategory ">
                    <label className="main-content-label my-auto pt-2">
                      Wom Points
                    </label>
                    <div className="d-flex flex-row-reverse">
                      {/* <p className="add_cat" onClick={() => handleClickCategory()}>
                      Add Category
                    </p> */}
                      <Button
                        variant="outline-primary"
                        className="rounded-2"
                        onClick={() => handleClickCategory()}
                      >
                        Add
                      </Button>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body>
                  <DataTableExtensions {...tableData}>
                    <DataTable
                      data={data}
                      columns={columns}
                      paginationServer={true}
                      pagination={true}
                      paginationRowsPerPageOptions={[50, 100, 200]}
                      paginationTotalRows={totalRows}
                      //   onRowClicked={handleRowClicked}
                      onChangeRowsPerPage={(p) => {
                        setPerPage(p);
                        setCurrentPage(1);
                      }}
                      onChangePage={(p) => setCurrentPage(p)}
                    />
                  </DataTableExtensions>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Modal show={deleteCategory} size="large">
            <Modal.Header
              closeButton
              onClick={() => {
                setDeleteCategory(false);
              }}
            >
              <h6>Delete Category </h6>
            </Modal.Header>
            <Modal.Body>
              Do you want to delete <strong> {deleteCategory?.name_en}</strong>{" "}
              category?
              <br />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={() => {
                  handleDeleteCategory();
                }}
                className="text-center"
              >
                Continue
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  setDeleteCategory(undefined);
                }}
                className="text-center"
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </Fragment>

        <AddPoints
          opens={opencategory}
          onClose={handleClose}
          onUpdate={() => showTierItems()}
          data={editCategory}
          isTrue={isUpdate}
        />
      </div>
    );
  }
}
export default WomPoints;
