import BaseURL from "../../data/Api/baseUrl";

const getAll = (currentPage, pageLimit) => {
  return BaseURL.get(
    `admin/offers/get_offers?page=${currentPage}&limit=${pageLimit}`
  );
};

const get = (id) => {
  return BaseURL.get(`admin/offers/get_offers_details/${id}`);
};

const create = (data) => {
  return BaseURL.postForm(`admin/offers/create`, data);
};

const update = (data) => {
  return BaseURL.postForm(`admin/offers/create`, data);
};

const remove = (_id) => {
  return BaseURL.delete(`category/${_id}`);
};

const OfferService = {
  getAll,
  get,
  create,
  update,
  remove,
};

export default OfferService;
