import BaseURL from "../../data/Api/baseUrl";
const get = () => {
  return BaseURL.get(
    "admin/store-settings/get-store-current-settings"
  );
};

const create = (data) => {
  return BaseURL.postForm(
    `admin/store-settings/create-or-update`,
    data
  );
};

// const update = (data) => {
//   return BaseURL.postForm(
//     `admin/app_settings/create_edit_app_setting`,
//     data
//   );
// };

// const remove = (_id) => {
//   return BaseURL.delete(`admin/app_settings/delete_app_setting/${_id}`);
// };

const createShopTier = (data) => {
  return BaseURL.postForm(
    `admin/shop_tier_items/add_edit_item`,
    data
  );
};
const getBoutiqueItems = (page, limit) => {
  return BaseURL.get(
    `admin/shop_tier_items/get_shop_items?page=${page}&limit=${limit}`
  );
};
const ShopSettingsService = {
  //   getAll,
  get,
  create,
  //   update,
  //   remove,
  createShopTier,
  getBoutiqueItems,
};

export default ShopSettingsService;
