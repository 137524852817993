import BaseURL from "../../data/Api/baseUrl";

const getAll = () => {
  return BaseURL.get(`admin/app_settings/get_app_settings`);
};

const get = (id) => {
  return BaseURL.get(`admin/offers/get_offers_details/${id}`);
};

const create = (data) => {
  return BaseURL.postForm(
    `admin/app_settings/create_edit_app_setting`,
    data
  );
};

const update = (data) => {
  return BaseURL.postForm(
    `admin/app_settings/create_edit_app_setting`,
    data
  );
};

const remove = (_id) => {
  return BaseURL.delete(`admin/app_settings/delete_app_setting/${_id}`);
};
const creteLoyalityItems = (data) => {
  return BaseURL.postForm(
    "admin/loyalty_tier_items/add_edit_item",
    data
  );
};
const getLoyalityItems = (page, limit) => {
  return BaseURL.get(
    `admin/loyalty_tier_items/get_tier_items?page=${page}&limit=${limit}`
  );
};
const AppSettingService = {
  getAll,
  get,
  create,
  update,
  remove,
  creteLoyalityItems,
  getLoyalityItems,
};

export default AppSettingService;
