import React, { useState, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import OfferService from "../../../Redux/services/OfferService";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Badge, Form } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { Button, Modal, Spinner } from "react-bootstrap";
import moment from "moment";
import { Chip } from "@mui/material";
import Select from "react-select";
// import "./offer.scss";
import BaseURL from "../../../data/Api/baseUrl";
import { Title } from "chart.js";
import AppSettingService from "../../../Redux/services/AppSettingService";

function AddPoints({ opens, onClose, onUpdate, data, isTrue }) {
  const [offerTitle, setOfferTitle] = useState();
  const [imgEng, setImageEng] = useState(undefined);
  const [imgAr, setImageAr] = useState(undefined);
  const [expiryDate, setExpiryDate] = useState("");
  const [offerCodeColl, setOfferCodeColl] = useState("");

  const [shopSearchKey, setShopSearchKey] = useState("");
  const [loadingshopSearch, setLoadingShopSearch] = useState(false);
  const [shopSearchResult, setShopSearchResult] = useState([]);
  const [loyalities, setLoyalities] = useState([]);

  const [shopId, setShopId] = useState("");
  const [pointAmount, setPointAmount] = useState();
  const [loyalityTier, setLoyalityTier] = useState(false);
  // Error message
  const [errors, setError] = useState({});
  const [errorMessage, setErrors] = useState(undefined);
  const [loadingCategory, setLoadingCategory] = useState(false);
  const [maximumUsage, setMaximumUsage] = useState();
  const [maxUsePer, setMaxUsePer] = useState();
  console.log(isTrue, data);
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 27) {
        handleClose();
      }
    };

    if (opens) {
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [opens, handleClose, offerCodeColl]);

  const notify = () => toast("Wom Points Created Successfully!");

  const handleClose = () => {
    onClose();
    setOfferTitle("");
    setError({});
    setImageEng(undefined);
    setImageAr(undefined);
    setPointAmount("");
    setExpiryDate("");
    setOfferCodeColl("");
    setMaxUsePer("");
    setMaximumUsage("");
    setShopId("");
    setLoyalityTier("");
    setError();
  };
  useEffect(() => {
    setOfferTitle(data?.title);
    setPointAmount(data?.point_amount);
    setMaxUsePer(data?.maximum_usage_per_user);
    setMaximumUsage(data?.maximum_usage);
    setLoyalityTier(data?.loyalty?.id);
  }, [data]);
  console.log(maxUsePer);
  const shopSearchData = () => {
    setLoadingShopSearch(true);
    BaseURL.get("admin/get_shops", {
      params: {
        search: shopSearchKey,
        limit: 20,
        page: 1,
      },
    })
      .then((res) => {
        let _tempData = res.data?.data?.data ?? [];
        console.log(_tempData);
        let _sortedData = _tempData.map((e) => {
          return {
            value: e.id,
            label: e.name_en,
          };
        });

        setShopSearchResult(_sortedData);
      })
      .finally(() => {
        setLoadingShopSearch(false);
      });
  };
  const getAllLoyality = () => {
    setLoadingShopSearch(true);
    BaseURL.get("admin/loyalty/get_tiers", {
      params: {
        search: shopSearchKey,
        limit: 20,
        page: 1,
      },
    })
      .then((res) => {
        let _tempData = res.data?.data?.data ?? [];
        console.log(_tempData);
        let _sortedData = _tempData.map((e) => {
          return {
            value: e.id,
            label: e.name_en,
          };
        });

        setLoyalities(_sortedData);
      })
      .finally(() => {
        setLoadingShopSearch(false);
      });
  };

  useEffect(() => {
    shopSearchData();
    getAllLoyality();
  }, [shopSearchKey]);

  const handleClick = (value) => {
    setOfferCodeColl(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    const errors = {};
    // if (!offerTitle) {
    //   errors.offerTitle = "Offer Title Required";
    // }
    // if (!imgEng) {
    //   errors.imgEng = "Image English Required";
    // }
    // if (!imgAr) {
    //   errors.imgAr = "Image Arabic Required";
    // }
    if (!pointAmount) {
      errors.pointAmount = "Point Amount Required";
    }
    if (!maximumUsage && !maximumUsage > 0) {
      errors.maximumUsage =
        "Maximum Usage Required and should be Greater than 0";
    }
    if (!maxUsePer) {
      errors.maxUsePer = "Maximum Usage Per user Is Required";
    }
    if (!loyalityTier) {
      errors.loyalityTier = "Loyality Is Required";
    }
    setLoadingCategory(true);
    setErrors(undefined);
    if (Object.keys(errors).length === 0) {
      try {
        const formData = new FormData();
        formData.append("title", offerTitle);
        // formData.append("expired_at", moment(expiryDate).format("DD-MM-YYYY"));
        // formData.append("offer_at", offerCodeColl);
        formData.append("image_url", imgEng);
        formData.append("loyalty_tier_id", loyalityTier);
        formData.append("shop_id", shopId);
        formData.append("point_amount", pointAmount);
        formData.append("maximum_usage", maximumUsage);
        formData.append("maximum_usage_per_user", maxUsePer);
        if (isTrue && data) {
          formData.append("id", data?.id);
        }
        // formData.append("shop_id", shopid);
        await AppSettingService.creteLoyalityItems(formData)
          .then((res) => {
            onUpdate();
            console.log("res", res.data);
          })
          .catch((err) => {
            console.log("add", err);
            if (
              err.response &&
              err.response.data &&
              err.response.data.message
            ) {
              setErrors(err.response.data.message);
              setErrors(err.response.data.errors.expired_at);
              console.log(
                "data_megaes",
                err.response.data.data.errors.expired_at
              );
            } else {
              setErrors("Unknown error occurred");
            }
          });
        handleClose();
        notify();
      } finally {
        setLoadingCategory(false); // Set loading to false, not true
      }
    }
    setError(errors);
    setLoadingCategory(false);
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div className="addCategory">
      <Modal show={opens} size="lg" backdrop="static">
        <Modal.Header
          closeButton
          className="add_categorys"
          onClick={handleClose}
        >
          <Modal.Title>
            <p>{isTrue ? "Update Loyalty Items" : "Create Loyalty Items"}</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className=" mb-3 updatedoctor">
            {/* {!updateApp && ( */}
            <div>
              <label>Shop</label>
              <Select
                closeMenuOnSelect={true}
                options={shopSearchResult}
                isSearchable={true}
                isLoading={loadingshopSearch}
                onInputChange={(newSearchKey) => setShopSearchKey(newSearchKey)}
                onChange={(selectedOption) => {
                  setShopId(selectedOption?.value);
                }}
                defaultValue={
                  isTrue
                    ? shopSearchResult.find(
                      (option) => option.value === data?.shop_id || null
                    )
                    : ""
                }
              />
              <div className="pt-3"></div>
              <label>Loyality level</label>
              <Select
                closeMenuOnSelect={true}
                options={loyalities}
                isLoading={loadingshopSearch}
                onChange={(selectedOption) => {
                  setLoyalityTier(selectedOption.value);
                }}
                defaultValue={
                  isTrue
                    ? loyalities.find(
                      (option) => option.value === data?.loyalty?.id || null
                    )
                    : ""
                }
              />
              <div>
                {loyalityTier ? (
                  <div></div>
                ) : (
                  <div className="error">
                    {errors?.loyalityTier && (
                      <span className="error" style={{ color: "red" }}>
                        {errors?.loyalityTier}
                      </span>
                    )}
                  </div>
                )}
              </div>
              <div className="pt-3"></div>
            </div>
            {/* )} */}
            <label>Title</label>
            <input
              type="text"
              placeholder="Enter a Title"
              className="form-control"
              name="title"
              onChange={(e) => setOfferTitle(e.target.value)}
              value={offerTitle}
            />
            <label className="pt-3">Image</label>
            <input
              type="file"
              placeholder="Upload Image"
              className="form-control"
              name="imgEng"
              onChange={(e) => setImageEng(e.target.files[0])}
            />
            {imgEng ? (
              <div></div>
            ) : (
              <div className="error">
                {errors?.imgEng && (
                  <span className="error" style={{ color: "red" }}>
                    {errors?.imgEng}
                  </span>
                )}
              </div>
            )}
            <div className="pt-3"></div>
            <label>Point Amount</label>
            <input
              type="number"
              placeholder="Enter Points"
              className="form-control"
              name="point_amount"
              onChange={(e) => setPointAmount(e.target.value)}
              value={pointAmount ? pointAmount : ""}
            />
            <div>
              {pointAmount ? (
                <div></div>
              ) : (
                <div className="error">
                  {errors?.pointAmount && (
                    <span className="error" style={{ color: "red" }}>
                      {errors?.pointAmount}
                    </span>
                  )}
                </div>
              )}
            </div>
            <div className="pt-3"></div>
            <label>Maximum Usage</label>
            <input
              type="number"
              placeholder="Enter Maximum Usage"
              className="form-control"
              name="point_amount"
              onChange={(e) => setMaximumUsage(e.target.value)}
              value={maximumUsage ? maximumUsage : ""}
            />
            <div>
              {maximumUsage ? (
                <div></div>
              ) : (
                <div className="error">
                  {errors?.maximumUsage && (
                    <span className="error" style={{ color: "red" }}>
                      {errors?.maximumUsage}
                    </span>
                  )}
                </div>
              )}
            </div>
            <div className="pt-3"></div>
            <label>Maximum Usage Per User</label>
            <input
              type="number"
              placeholder="Enter Maximum Usage per User"
              className="form-control"
              name="point_amount"
              onChange={(e) => setMaxUsePer(e.target.value)}
              value={maxUsePer ? maxUsePer : ""}
            />
            <div>
              {maxUsePer ? (
                <div></div>
              ) : (
                <div className="error">
                  {errors?.maxUsePer && (
                    <span className="error" style={{ color: "red" }}>
                      {errors?.maxUsePer}
                    </span>
                  )}
                </div>
              )}
            </div>
            <div className="pt-3"></div>
            {errorMessage ? (
              <div>
                <Badge pill bg="danger">
                  {errorMessage.toString()}
                </Badge>
              </div>
            ) : (
              <></>
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleSubmit}
          // disabled={loadingCategory}
          >
            {/* {loadingCategory ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : ( */}
            <span>Add</span>
            {/* )} */}
          </Button>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default AddPoints;
